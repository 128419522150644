import React, { useState } from 'react';
import './LandingPage.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoFile from "../../Components/images/bodha-video-4.mp4"
import { Link } from 'react-router-dom';
import HeaderForm from '../../Components/HeaderForm';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow, EffectCreative } from 'swiper';
import "swiper/css"
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-coverflow";
import "swiper/css/effect-cube"
import LandingStrip from '../../Components/LandingStrip';
  
const LandingPage = () => {


  var settings3 = {
    infinite: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    arrows: true,
    dots: false,
    fade: true,
    speed: 2000,
  };

  var settings4 = {
    infinite: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    dots: false,
    speed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (

    <div className="LandingPage">
      <div className="landingpage">
        <div className="landing-page-inner">
          <div className="landing-slider-section">
            {/* <div className='fire-fliers'>
              <div className="firefly" id="0"></div>
              <div className="firefly" id="1"></div>
              <div className="firefly" id="2"></div>
              <div className="firefly" id="3"></div>
              <div className="firefly" id="4"></div>
              <div className="firefly" id="5"></div>
              <div className="firefly" id="6"></div>
              <div className="firefly" id="7"></div>
              <div className="firefly" id="8"></div>
              <div className="firefly" id="9"></div>
              <div className="firefly" id="10"></div>
              <div className="firefly" id="11"></div>
              <div className="firefly" id="12"></div>
              <div className="firefly" id="13"></div>
              <div className="firefly" id="14"></div>
              <div className="firefly" id="15"></div>
              <div className="firefly" id="16"></div>
              <div className="firefly" id="17"></div>
              <div className="firefly" id="18"></div>
              <div className="firefly" id="19"></div>
              <div className="firefly" id="20"></div>
              <div className="firefly" id="21"></div>
              <div className="firefly" id="22"></div>
              <div className="firefly" id="23"></div>
              <div className="firefly" id="24"></div>
              <div className="firefly" id="25"></div>
              <div className="firefly" id="26"></div>
              <div className="firefly" id="27"></div>
              <div className="firefly" id="28"></div>
              <div className="firefly" id="29"></div>
              <div className="firefly" id="30"></div>
              <div className="firefly" id="31"></div>
              <div className="firefly" id="32"></div>
              <div className="firefly" id="33"></div>
              <div className="firefly" id="34"></div>
              <div className="firefly" id="35"></div>
              <div className="firefly" id="36"></div>
              <div className="firefly" id="37"></div>
              <div className="firefly" id="38"></div>
              <div className="firefly" id="39"></div>
              <div className="firefly" id="40"></div>
              <div className="firefly" id="41"></div>
              <div className="firefly" id="42"></div>
              <div className="firefly" id="43"></div>
              <div className="firefly" id="44"></div>
              <div className="firefly" id="45"></div>
              <div className="firefly" id="46"></div>
              <div className="firefly" id="47"></div>
              <div className="firefly" id="48"></div>
              <div className="firefly" id="49"></div>
              <div className="firefly" id="50"></div>
              <div className="firefly" id="51"></div>
              <div className="firefly" id="52"></div>
              <div className="firefly" id="53"></div>
              <div className="firefly" id="54"></div>
              <div className="firefly" id="55"></div>
              <div className="firefly" id="56"></div>
              <div className="firefly" id="57"></div>
              <div className="firefly" id="58"></div>
              <div className="firefly" id="59"></div>
              <div className="firefly" id="60"></div>
              <div className="firefly" id="61"></div>
              <div className="firefly" id="62"></div>
              <div className="firefly" id="63"></div>
              <div className="firefly" id="64"></div>
              <div className="firefly" id="65"></div>
              <div className="firefly" id="66"></div>
              <div className="firefly" id="67"></div>
              <div className="firefly" id="68"></div>
              <div className="firefly" id="69"></div>
              <div className="firefly" id="70"></div>
              <div className="firefly" id="71"></div>
              <div className="firefly" id="72"></div>
              <div className="firefly" id="73"></div>
              <div className="firefly" id="74"></div>
              <div className="firefly" id="75"></div>
              <div className="firefly" id="76"></div>
              <div className="firefly" id="77"></div>
              <div className="firefly" id="78"></div>
              <div className="firefly" id="79"></div>
              <div className="firefly" id="80"></div>
              <div className="firefly" id="81"></div>
              <div className="firefly" id="82"></div>
              <div className="firefly" id="83"></div>
              <div className="firefly" id="84"></div>
              <div className="firefly" id="85"></div>
              <div className="firefly" id="86"></div>
              <div className="firefly" id="87"></div>
              <div className="firefly" id="88"></div>
              <div className="firefly" id="89"></div>
              <div className="firefly" id="90"></div>
              <div className="firefly" id="91"></div>
              <div className="firefly" id="92"></div>
              <div className="firefly" id="93"></div>
              <div className="firefly" id="94"></div>
              <div className="firefly" id="95"></div>
              <div className="firefly" id="96"></div>
              <div className="firefly" id="97"></div>
              <div className="firefly" id="98"></div>
              <div className="firefly" id="99"></div>
            </div> */}
            <div className="landing-logo shadow-lg">
              <img src={require("../../Components/images/landing-logo-2.png")} alt="" />
            </div>
            <Slider {...settings3}>
              <div className="landing-sec-1">
                <div className="landing-sec-1-inner">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        {/* <div className="left-side-lander">
                        <img className="icon-changer" src={require("../../Components/images/smithakrishna-png.png")} alt="" />
                    </div> */}
                        <div className="right-side-lander">
                          <h1 className='text-dark'>We all need a Financial Advisor in our lives </h1>
                          <h2 className="right-last-p mb-2 text-dark">A Perfect Advisor is all you should ask for</h2>
                          <h2 className='colored-text-2'>Smitha Krishnamurthy</h2>
                          {/* <img src={require("../../Components/images/smitha-word.png")} alt="" /> */}
                          {/* <h2 className="colored-text">𝓢𝓶𝓲𝓽𝓱𝓪 𝓚𝓻𝓲𝓼𝓱𝓷𝓪𝓶𝓾𝓻𝓽𝓱𝔂</h2> */}
                          <p className='text-dark'>
                            Real Estate Advisor/ Expert <br />
                            Chairman & Founder -  Bodha Homes Infra Pvt Ltd.
                          </p>

                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="slide-1-img-blk">
                          <img src={require("../../Components/images/slide-1-img.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="landing-sec-2">
                <div className="landing-sec-2-inner">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="land-sec-2-right">
                          <img src={require("../../Components/images/slide-2-img-2.png")} alt="" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="land-sec-2-left">
                          <h2 className="text-center">Invest wisely</h2>
                          <p className="text-center"> We are here to Serve you</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="landing-sec-3">
                <div className="landing-sec-3-inner">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="land-sec-3-left text-center">
                          <h2 className='text-center'>Your Investment <br />should Reap</h2>
                          <p className='mt-3'>Make a difference</p>
                          <a href="#a">Consult us</a>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="land-sec-3-right">
                          <img src={require("../../Components/images/slide-3-img.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div className="landing-blk-1">
            <div className="landing-blk-1-inner">
              <div className="container">
                <div className="landing-page-form-container-blk text-center d-flex justify-content-center">
                  <div className="landing-page-form shadow-lg">
                    <HeaderForm />
                  </div>
                </div>
                <div className="row" id="moverdown">
                  <div className="col-md-3">
                    <a href='https://forms.gle/juiPe25JveRi3buHA' target="_blank">
                      <div className="yellow-blks m-yellowblk-b d-flex justify-content-between align-items-center shadow wow animate__fadeInUp">
                        <p>Book a free Property Consultation.</p>
                        <img className="icon-changer" src={require("../../Components/images/booknow-1.png")} alt="" />
                        <img className="icon-changer2" src={require("../../Components/images/booknow-2.png")} alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-3">
                    <Link to="/ongoingprojects" target={"_blank"}>
                      <div className="yellow-blks m-yellowblk-b d-flex justify-content-between align-items-center shadow wow animate__fadeInUp" data-wow-delay="0.20s">
                        <p>Our sites</p>
                        <img className="icon-changer" src={require("../../Components/images/oursite-1.png")} alt="" />
                        <img className="icon-changer2" src={require("../../Components/images/oursite-2.png")} alt="" />
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3">
                    <a href="#landing-blk-4">
                      <div className="yellow-blks yellow-blk-375 d-flex justify-content-between align-items-center shadow wow animate__fadeInUp" data-wow-delay="0.30s">
                        <p>Join us as an Associate</p>
                        <img className="icon-changer" src={require("../../Components/images/joinus-1.png")} alt="" />
                        <img className="icon-changer2" src={require("../../Components/images/joinus-2.png")} alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-3">
                    <Link to="/">
                      <div className="yellow-blks d-flex justify-content-between align-items-center shadow wow animate__fadeInUp" data-wow-delay="0.40s">
                        <p>Know more</p>
                        <i class="fa-regular fa-circle-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-blk-2" id='movedownsec'>
            <div className="landing-blk-2-inner">
              <div className="container">
                <div className="land-blk-2-head d-flex justify-content-center align-items-center">
                  <h2 className='text-center'> We are here to Serve you</h2>
                  <img src={require("../../Components/images/house-animation.gif")} alt="" />
                  {/* <img src={require("../../Components/images/house-animation-2.gif")} alt=""/> */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="land-blk-3-left wow animate__fadeInLeft">
                      <video id="myVideo2" loop="loop" width="100%" height="100%" controls>
                        <source src={VideoFile} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="land-blk-3-right">
                      <p><strong>Bodha Homes Infra</strong> has Firmly Established its Roots in Real Estate Industry from 2011.
                        Started as a construction Firm, Now established as a Private Limited Company and has marked
                        an incredible success in Hyderabad Real Estate Retail Industry.Now Bodha Homes Infra More than 100000 Clients,
                        we are serving the Industry in various Domain marking ourselves as Marketing, Consulting & Construction
                        Company serving people in Buying & Selling Properties and managing all their Investment Portfolios’
                        The Bodha Homes Infra has diversified over Period of time into a number of Real Estate Related Services
                        each of them handled & Executed with adroit Capacity.
                      </p>
                      <Link to="/about"><button className="text-white text-center wow animate__backInDown" data-wow-delay="0.40s">See full bio</button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-exp-blk">
            <div className="landing-exp-inner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="left-exp-blk">
                      <div className="two-exp-blk d-flex justify-content-center align-items-center">
                        <div className="exp-blk-1">
                          <h2>22</h2>
                        </div>
                        <div className="exp-blk-2 ps-3">
                          <h2>Years of experience</h2>
                          <p>Glorious 22 years of experience and success in changing lives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="right-exp-blk">
                      <div className="right-exp-1 d-flex justify-content-center align-items-center">
                        <div className="right-exp-inner-1 text-center">
                          <h2>10+</h2>
                          <p>MNC</p>
                        </div>
                        <div className="right-exp-inner-2 text-center">
                          <p>worked with more than </p>
                          <h2>1,00,000</h2>
                          <p>Associates</p>
                        </div>
                      </div>
                      <div className="right-exp-inner-3">
                        <h2 className='text-center'>Trend Setter In <span>Realestate</span></h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-store-blk">
            <div className="lading-store-inner">
              <div className="container">
                <div className="store-head mb-4">
                  <h2 className='text-center'>Store</h2>
                </div>
                <div className="row justify-content-center  ">
                  <div className="col-md">
                    <div className="agriculture bgsamer shadow-lg  wow animate__fadeInUp">
                      <img src={require("../../Components/images/agriculturelands.png")} alt="" />
                      <strong><p className='text-center'>Agriculture Lands</p></strong>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="dtcp-products bgsamer shadow-lg wow animate__fadeInUp" data-wow-delay="0.20s">
                      <img src={require("../../Components/images/project-1-1.png")} alt="" />
                      <strong><p className='text-center'>HMDA - DTCP Products</p></strong>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="farm bgsamer shadow-lg wow animate__fadeInUp" data-wow-delay="0.30s">
                      <img src={require("../../Components/images/farmlands.png")} alt="" />
                      <strong><p className='text-center'>Farm Land</p></strong>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="land-aqui bgsamer shadow-lg wow animate__fadeInUp" data-wow-delay="0.50s">
                      <img src={require("../../Components/images/aquisition.png")} alt="" />
                      <strong><p className='text-center'>Land Aquisation for Coorporates</p></strong>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="flats-villas bgsamer shadow-lg wow animate__fadeInUp" data-wow-delay="0.60s">
                      <img src={require("../../Components/images/flats-villas.png")} alt="" />
                      <strong><p className='text-center'>Flats - Villas</p></strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-blk-3">
            <div className="landing-blk-3-inner">
              <div className="container">
                <div className="landing-blk-3-head">
                  <h2 className='text-center'>Reviews</h2>
                </div>
                <div className="testimonials-blk">
                  <Slider {...settings4}>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Saurabh Chariahh</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"I have moved 6 times in the last 25 years. Obviously,
                            we've dealt with many realtors both on the buying and selling side.
                            I have to say that <span> Bodha Homes Infra </span> is by far the BEST realtor we've ever worked with"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Guna Sri</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"<span> Bodha Homes Infra </span> gave us many helpful suggestions on how to update our home.
                            They are very professional and did a very in depth market analysis to help us determine in buying a new Home
                            and This team is very responsive to any questions or concerns that we had and were always wonderful to deal with"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Radha Krishna</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"When you're making a big financial,
                            personal and sometimes emotional decision, you want someone who will be a good listener,
                            a strong negotiator and who is respected his/her peers. <span> Bodha Homes Infra </span> meets all expectations"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Ganesh</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"We had a very positive experience with <span> Bodha Homes Infra </span>
                            in search for & purchase of our new home. we stayed positive through the entire process.
                            I would definitely recommend <span> Bodha Homes Infra </span>  to anyone looking to buy a home."
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Arjun Rathod</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"<span> Bodha Homes Infra </span> gave us many helpful suggestions on how to update our home.
                            They are very professional and did a very in depth market analysis to help us determine in buying a new Home
                            and This team is very responsive to any questions or concerns that we had and were always wonderful to deal with"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Seby Varghese</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"When you're making a big financial,
                            personal and sometimes emotional decision, you want someone who will be a good listener,
                            a strong negotiator and who is respected his/her peers. <span> Bodha Homes Infra </span> meets all expectations"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Saket Agarwal</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"We had a very positive experience with <span> Bodha Homes Infra </span>
                            in search for & purchase of our new home. we stayed positive through the entire process.
                            I would definitely recommend <span> Bodha Homes Infra </span>  to anyone looking to buy a home."
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-landing">
                      <div className="testimonial-img text-center">

                      </div>
                      <div className="landing-test-content">
                        <div className="reviewer">
                          <h4 className="text-center">Viswanth</h4>
                          <div className="star-icons d-flex justify-content-center">
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="stars">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                          </div>
                          <p className="text-center">"We had a very positive experience with <span> Bodha Homes Infra </span>
                            in search for & purchase of our new home. we stayed positive through the entire process.
                            I would definitely recommend <span> Bodha Homes Infra </span>  to anyone looking to buy a home."
                          </p>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-blk-4" id="landing-blk-4">
            <div className="landing-blk-4-inner">
              <div className="container">
                <div className="row">                  
                  <div className="col-md-6">                  
                    <HeaderForm />
                  </div>
                  <div className="col-md-6">

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-blk-5">
            <div className="landing-blk-5-inner">
              <div className="container">
                <div className="land-blk-5-head mb-3">
                  <h2 className='text-center'>Our Gallery</h2>
                </div>
                <div className="image-gallery">
                  <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    spaceBetween={30}
                    slidesPerView={2}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    loop={true}
                    navigation={{ clickable: true }}
                    pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {/* <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.60s">
                        <img src={require("../../Components/images/gallery-pic-6.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown">
                        <img src={require("../../Components/images/gallery-pic-1.png")} alt="" />
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.20s">
                        <img src={require("../../Components/images/gallery-pic-2.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.30s">
                        <img src={require("../../Components/images/gallery-pic-3.png")} alt="" />
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.40s">
                        <img src={require("../../Components/images/gallery-pic-4.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.50s">
                        <img src={require("../../Components/images/gallery-pic-5.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.70s">
                        <img src={require("../../Components/images/gallery-pic-7.png")} alt="" />
                      </div>
                    </SwiperSlide> */}
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.40s">
                        <img src={require("../../Components/images/gallery-pic-8.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.50s">
                        <img src={require("../../Components/images/gallery-pic-9.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.60s">
                        <img src={require("../../Components/images/gallery-pic-10.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.80s">
                        <img src={require("../../Components/images/gallery-pic-11.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.90s">
                        <img src={require("../../Components/images/gallery-pic-12.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1s">
                        <img src={require("../../Components/images/gallery-pic-13.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/gallery-pic-14.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/gallery-pic-15.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-1.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-13333.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-156.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-1656.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-16566.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages//Untitled-178.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-1989.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-22.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/slider11111.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/slider22222.png")} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="image-gallery-2 d-none">
                  <Swiper
                    grabCursor={true}
                    effect={"creative"}
                    creativeEffect={{
                      prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                      },
                      next: {
                        translate: ["100%", 0, 0],
                      },
                    }}
                    className="mySwiper"
                    loop={true}
                    navigation={{ clickable: true }}
                    pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    modules={[EffectCreative, Pagination, Navigation]}
                  >
                    {/* <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.60s">
                        <img src={require("../../Components/images/gallery-pic-6.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown">
                        <img src={require("../../Components/images/gallery-pic-1.png")} alt="" />
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.20s">
                        <img src={require("../../Components/images/gallery-pic-2.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.30s">
                        <img src={require("../../Components/images/gallery-pic-3.png")} alt="" />
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.40s">
                        <img src={require("../../Components/images/gallery-pic-4.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.50s">
                        <img src={require("../../Components/images/gallery-pic-5.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.70s">
                        <img src={require("../../Components/images/gallery-pic-7.png")} alt="" />
                      </div>
                    </SwiperSlide> */}
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.40s">
                        <img src={require("../../Components/images/gallery-pic-8.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.50s">
                        <img src={require("../../Components/images/gallery-pic-9.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.60s">
                        <img src={require("../../Components/images/gallery-pic-10.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.70s">
                        <img src={require("../../Components/images/gallery-pic-11.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="0.90s">
                        <img src={require("../../Components/images/gallery-pic-12.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1s">
                        <img src={require("../../Components/images/gallery-pic-13.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/gallery-pic-14.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-1.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-13333.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-156.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-1656.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-16566.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages//Untitled-178.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-1989.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/Untitled-22.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/slider11111.png")} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="images-gallery wow animate__fadeInDown" data-wow-delay="1.10s">
                        <img src={require("../../Components/images/galeryimages/slider22222.png")} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-blk-6">
            <div className="landing-blk-6-inner">
              <div className="container">
                <div className="land-blk-6-head mb-3">
                  <h2 className="text-center">Our Videos</h2>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={8}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/YQd2Jlh-ldo" title="Villa for Sale at Fortune Butterfly City, Kadtal for 1.2 cr Ready to Move in." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/Ph7cwQozIfA" title="Resale DTCP Final approved Plots for Sale at Kadtal. Cheaper Prize. Limited Plots Available" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/OOvToPjgh1k" title="10 Guntas Farm Plot for Sale at Kadtal Adjacent to Butterfly City" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/LWbvUu4JnVM" title="Resale DTCP final approved plots for Sale at Kadtal for half the market prize" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/6hYIupYj49s" title="Resale Villa Plots at Shamshabad facing Bangalore Highway." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sixth">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/ytIXQoet1Go" title="4 Acres land for Sale @ D Nagaram. Choutuppal." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="seventh">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/uItNh8TQLSE" title="VD-33.Resale Plots At Thimmapur, Kothur. HMDA Final approved layout At a very affordable Prize." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eighteen">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/dzDK32xZQvk" title="318 Sq yards West facing Plot for Sale at Mansanpally X-Roads." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ninteen">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/qKor24JBPKA" title="Most Affordable budget buy plots on Bangalore Highway starting from 8 Lacs..." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tenth">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/3Ivo77iZX6A" title="Triplex Villa for Sale on Nizampet Road for just 3Cr." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eleven">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/512kBsjuf0c" title="1.29 Acres of land for Sale at Kothaguda near Pochampally. Vijayawada Highway" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="twelve">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/Mi3rmM-HPfQ" title="Quarter Acre land for Sale at Choutuppal for a very reasonable prize suitable for farm house." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="thirteen">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/HkRfyv73ZxI" title="5 Acres land for Sale on Khana Shanthi Vanam Road, Chegur. Shadnagar. Bangalore Highway." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourteen">
                          <div className="iframe-outer-sec">
                            <iframe className="iframer-video" src="https://www.youtube.com/embed/CGiNfV_5Swg" title="Resale plots in Shamshabad for a Reasonable Prize" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                    <Col sm={4}>
                      <Nav variant="pills" className="flex-column youtube-autoplay-outer-sec">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-1.jpg")} alt="" />
                              <p>Villa for sale at Fortune Butterfly City</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-2.jpg")} alt="" />
                              <p>Resale Plots in Final Approved DTCP</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-3.jpg")} alt="" />
                              <p>10 Guntas Farm Plot</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-4.jpg")} alt="" />
                              <p>Resale Plots at Kadtal @ half the Market Price</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fifth">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-5.jpg")} alt="" />
                              <p>Resale Villa Plots at Shamshabad</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="sixth">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-6.jpg")} alt="" />
                              <p>4 Acres Land For Sale </p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="seventh">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-7.jpg")} alt="" />
                              <p>Resale Plots at Thimmapur</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="eighteen">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-8.jpg")} alt="" />
                              <p>Resale Plot | Reasonable Price </p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="ninteen">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-9.jpg")} alt="" />
                              <p>Resale Plots From 8 Lacs @ Banglore</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tenth">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-10.jpg")} alt="" />
                              <p>Triplex Villa for Sale on Nizampet Road</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="eleven">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-11.jpg")} alt="" />
                              <p>1.29 Acres Land for Sale @ Kothaguda</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="twelve">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-12.jpg")} alt="" />
                              <p>Quarter Acre at the Cost of a Plot</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="thirteen">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-13.jpg")} alt="" />
                              <p>5 Acres R1 Zone Land for sale</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourteen">
                            <div className="youtube-auotplay-sec d-flex align-items-center">
                              <img src={require("../../Components/images/resale-14.jpg")} alt="" />
                              <p>Resale Villa Plots in Fort Grand Venture</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
          <div className="landing-our-services-blk">
            <div className="about-sec-2-inner">
              <div className="container">
                <div className="our-services-blk">
                  <h5 className='text-center wow animate__fadeInDown'>Our Services</h5>
                  <div className="our-services-para">
                    <p className='text-center wow animate__fadeInDown' data-wow-delay="0.20s">The Company has diversified over Period of time into a number
                      of Real Estate Related  Services each of them handled & Executed
                      with adroit Capacity.
                    </p>
                  </div>
                </div>
                <div className="row landing-our-services">
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/buying-prop.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Buying & Selling Properties</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.20s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/real-investment.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Bulk Real Estate Investments</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.30s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/roi-based.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>ROI Based Investments</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.40s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/villa.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Villas</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.50s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/openplots.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Open Plots</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.60s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/agriculture.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Agricultural Lands</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.70s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/budgethomes.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Budget Homes</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.80s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/premiumprop.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Premium Properties</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="0.90s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/leagladv.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Legal Advice</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="1s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/investadv.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Real Estate Investment Advices</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk no-marg-bottom-sec shadow d-flex align-items-center wow animate__fadeInUp" data-wow-delay="1.20s">
                      <div className="services-blk-img">
                        <img src={require("../../Components/images/interior.png")} alt="" />
                      </div>
                      <div className="about-services-content">
                        <p>Interior Designing</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="our-services-para2">
                  <p className="wow animate__fadeInUp" data-wow-delay="1.30s">
                    We are widely spread across South India with
                    Completed and On Going Projects at Kerala, Kurnool & Hyderabad Covering
                    the Commercial, Retail, Residential and Hospitality Segments.
                  </p>
                  <p className="wow animate__fadeInUp" data-wow-delay="1.40s">
                    We take pleasure in being a One Stop Point for every Investor/Buyer
                    looking out for secure Investment for the Future at a well Developed
                    Locality with Proper Connectivity.Its our Determination & Commitment with
                    Excellence to Enable our Services – One to One.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

LandingPage.propTypes = {};

LandingPage.defaultProps = {};

export default LandingPage;
