import React, { useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import './Blog.css';

const Blog = () => {
  const [isOpener, setIsOpener] = useState({
    videob1: false,
    videob2: false,
    videob3: false,
    videob4: false,
    videob5: false,
    videob6: false,
    videob7: false,
    videob8: false,
  });
  return (
    <div className="Blog">
      <Header/>
      <div className="inner-section single-banner">
        <div className="container">
          <h2>Blog</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Blog
            </li>
          </ol>
        </div>
      </div>
      <div className="blog-section">
        <div className="blog-inner">
          <div className="container">
            <div className="blog-1-sec">
              <div className="section-1-blog-inner">
                <h2 className="text-center mb-2">Blog</h2>
                {/* <p className="text-center mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting 
                industry. Lorem Ipsum has been the industry's<br/> standard dummy text ever since the 1500s, 
                when an unknown printer took a galley.</p> */}
                <div className="modals-block">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp">
                      <Modal
                        isOpen={isOpener.videob1}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob1: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/6d1hNabz3Nk?autoplay=1" title="Woman Entrepreneurs in our society." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob1: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob1: true }); }} src={require("../../Components/images/blog-1.png")} alt="" />
                    </div>
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp" data-wow-delay="0.20s">
                      <Modal
                        isOpen={isOpener.videob2}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob2: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/Vhne8KsodWc?autoplay=1" title="Select properties that are easy to trade." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob2: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob2: true }); }} src={require("../../Components/images/blog-2.png")} alt="" />
                    </div>
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp" data-wow-delay="0.30s">
                      <Modal
                        isOpen={isOpener.videob3}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob3: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/fDrJMyS2aRg?autoplay=1" title="Simple tips to save a fixed amount every month." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob3: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob3: true }); }} src={require("../../Components/images/blog-3.png")} alt="" />
                    </div>
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp" data-wow-delay="0.40s">
                      <Modal
                        isOpen={isOpener.videob4}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob4: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/za_Y8HpGoPw?autoplay=1" title="How to purchase property in your absence." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob4: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob4: true }); }} src={require("../../Components/images/blog-4.png")} alt="" />
                    </div>
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp" data-wow-delay="0.5s">
                      <Modal
                        isOpen={isOpener.videob5}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob5: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/X9fixVd9yC8?autoplay=1" title="Pharma city will change the face of Kadthal." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob5: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob5: true }); }} src={require("../../Components/images/blog-5.png")} alt="" />
                    </div>
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp" data-wow-delay="0.60s">
                      <Modal
                        isOpen={isOpener.videob6}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob6: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/j-Iypa5UwGs?autoplay=1" title="Would a House near your Office make a difference in Life?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob6: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob6: true }); }} src={require("../../Components/images/blog-6.png")} alt="" />
                    </div>
                    <div className="col-md-4 col-sm-4 wow animate__fadeInUp" data-wow-delay="0.70s">
                      <Modal
                        isOpen={isOpener.videob7}
                        onRequestClose={() => { setIsOpener({ ...isOpener, videob7: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/E8_RY4FsgmM?autoplay=1" title="How to make a long and short term investment in real estate." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsOpener({ ...isOpener, videob7: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p shadow-lg" onClick={() => { setIsOpener({ ...isOpener, videob7: true }); }} src={require("../../Components/images/blog-7.jpg")} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

Blog.propTypes = {};

Blog.defaultProps = {};

export default Blog;
