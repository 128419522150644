import React from "react";
import HeaderForm from "./HeaderForm";
import "../Components/Modalstyles.css"
import Modal from "react-modal";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import VideoFile from "./images/bodha-video-4.mp4"
import Header from "./Header";
import Footer from "./Footer";

const Bodha = () => {
const [isOpen, setIsopen] = useState({
    video1: false,
    video2: false,
    video3: false,
    video4: false,
    video5: false,
    video6: false,
    video7: false,
    video8: false,
    });
    var settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay : true,
        arrows : true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        var settings2 = {
            dots: true,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay : true,
            arrows : true,
            // responsive: [
            //     {
            //       breakpoint: 1200,
            //       settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         infinite: true,
            //       }
            //     },
            //     {
            //       breakpoint: 767,
            //       settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         initialSlide: 2
            //       }
            //     },
            //     {
            //       breakpoint: 480,
            //       settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1
            //       }
            //     }
            //   ]
        
            // nextArrow: ,
            // prevArrow:,
            };
  return <div>
    <Header/>
      <div className="bodha-infra">
          <div className="bodha-infra-inner">
              <div className="bodha-sec-1">
                  <div className="bodha-sec-1-inner">
                      <div className="container">
                          <div className="head-sec">
                            <div className="header-original">
                                <h1 className="text-white text-start wow animate__fadeInLeft">All Your Real Estate</h1>
                                <h1 className="text-white text-start wow animate__fadeInLeft" data-wow-delay="0.5s">Investment Needs</h1>
                                <h2 className="text-white text-start wow animate__fadeInLeft" data-wow-delay="0.75s">At One Place <strong>Bodha Homes Infra</strong> Pvt Ltd.</h2>
                            </div>
                            <div className="header-duplicate">
                                <h1 className="text-white text-start wow animate__fadeInLeft">All Your Real Estate Investment Needs @One Place <strong>Bodha Homes Infra</strong> Pvt Ltd.</h1>
                            </div>
                          </div>
                          <div className="form-sec wow animate__backInDown">
                            <HeaderForm/>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="bodha-sec-3">
                <div className="bodha-sec-3-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6  d-flex align-items-center">
                                {/*<div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="top-images-blk">
                                                <div className="top-image-1 mb-3 wow animate__fadeInUp">
                                                    <img src={require("../Components/images/left-img-1.png")} alt="" />
                                                </div>
                                                <div className="top-image-2 wow animate__fadeInUp" data-wow-delay="0.5s">
                                                    <img src={require("../Components/images/left-img-3.png")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="down-images-blk">
                                                <div className="down-image-1 mb-3 wow animate__fadeInDown">
                                                    <img src={require("../Components/images/left-img-2.png")} alt="" />
                                                </div>
                                                <div className="down-image-2 wow animate__fadeInDown" data-wow-delay="0.5s">
                                                    <img src={require("../Components/images/left-img-4.png")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                <div className="left-side-content">
                                    <video id="myVideo" loop="loop"  width="100%" height="100%" controls>
                                        <source src={VideoFile}  type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side-content wow animate__fadeInRight">
                                    <h2>Welcome to Bodha Homes Infra </h2>
                                    <div className="tag-line">
                                        <p className="text-dark text-left"><strong>All your Real Estate Investment Needs At One Place</strong></p>
                                    </div>
                                    <div className="first-para">
                                        <p className="text-dark text-left">Bodha Homes Infra has Firmly Established its Roots in Real Estate 
                                            Industry from 2011. Started as a construction Firm, Now established 
                                            as a Private Limited Company and has marked an incredible success 
                                            in Hyderabad Real Estate Retail Industry.
                                        </p>
                                    </div>
                                    <div className="second-para">
                                        <p className="text-dark text-left">Now Bodha Homes Infra More than 100000 Clients, we are serving the Industry in various 
                                            Domain marking ourselves as Marketing, Consulting & Construction Company 
                                            serving people in Buying & Selling Properties and managing all their 
                                            Investment Portfolios’
                                        </p>
                                    </div>
                                    <div className="third-para">
                                        <p>The Bodha Homes Infra has diversified over Period of time into a number of Real Estate Related 
                                             Services each of them handled & Executed with adroit Capacity.
                                            </p>
                                    </div>
                                    <Link to="/about"><button className="text-white text-center wow animate__backInDown" data-wow-delay="0.40s">Read More</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bodha-home-videos-blk">
                    <div className="bodha-home-videos-inner">
                        <div className="container">
                            <h2 className="text-center">Videos</h2>
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp">
                                        <Modal isOpen={isOpen.video1}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video1: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">
                                                <iframe className="iframer-video" src="https://www.youtube.com/embed/rNFumQydhJA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div>
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video1: false });}}                                >
                                                X
                                            </button>
                                        </div>                            
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video1: true });}} src={require("./images/homevid-1.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.30s">
                                        <Modal isOpen={isOpen.video2}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video2: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">
                                                <iframe className="iframer-video" src="https://www.youtube.com/embed/3cFQ37sOKBc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div>
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video2: false });}}                                >
                                                X
                                            </button>
                                        </div>                              
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video2: true });}} src={require("./images/homevid-2.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.40s">
                                        <Modal isOpen={isOpen.video3}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video3: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">
                                            <iframe className="iframer-video" src="https://www.youtube.com/embed/5OnayDXTvag" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div>
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video3: false });}}                                >
                                                X
                                            </button>
                                        </div>                              
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video3: true });}} src={require("./images/homevid-3.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.5s">
                                        <Modal isOpen={isOpen.video4}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video4: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">
                                            <iframe className="iframer-video" src="https://www.youtube.com/embed/J8PlQn-3dgo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div> 
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video4: false });}}                                >
                                                X
                                            </button>
                                        </div>                             
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video4: true });}} src={require("./images/homevid-4.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.70s">
                                        <Modal isOpen={isOpen.video5}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video5: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">
                                            <iframe  className="iframer-video" src="https://www.youtube.com/embed/CYpD6La6TMM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div>
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video5: false });}}                                >
                                                X
                                            </button>
                                        </div>                              
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video5: true });}} src={require("./images/homevid-5.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.40s">
                                        <Modal isOpen={isOpen.video6}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video3: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">
                                            <iframe className="iframer-video" src="https://www.youtube.com/embed/ZhqScZ8P3EA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div>
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video6: false });}}                                >
                                                X
                                            </button>
                                        </div>                              
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video6: true });}} src={require("./images/homevid-6.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.5s">
                                        <Modal isOpen={isOpen.video7}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video7: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">                                          
                                            <iframe className="iframer-video" src="https://www.youtube.com/embed/GXqe8tEHfME" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div> 
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video7: false });}} >
                                                X
                                            </button>
                                        </div>                             
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video7: true });}} src={require("./images/homevid-7.jpg")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="home-videos wow animate__fadeInUp" data-wow-delay="0.70s">
                                        <Modal isOpen={isOpen.video5}
                                          onRequestClose={() => {setIsopen({ ...isOpen, video8: false });}}
                                          contentLabel="My dialog"
                                          overlayClassName="myoverlay"
                                          className="mymodal ww">
                                          <div className="iframe-outer-sec">                                          
                                            <iframe  className="iframer-video" src="https://www.youtube.com/embed/6J2a0BLgQKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                          </div>
                                          <div className="close-button">
                                            <button
                                                type="close"
                                                className="text-danger"
                                                onClick={() => {setIsopen({ ...isOpen, video8: false });}}                                >
                                                X
                                            </button>
                                        </div>                              
                                        </Modal>
                                        <img className='curse-p shadow-lg' onClick={() => {setIsopen({ ...isOpen, video8: true });}} src={require("./images/homevid-8.jpg")} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
              </div>
              <div className="bodha-sec-2">
                <div className="bodha-sec-2-inner">
                    <div className="container">
                        <div className="know-projects">
                            <h2 className="text-center">Book your new property</h2>
                            {/* <p className="text-center text-dark">Book your new property </p> */}
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="project-images text-center wow animate__fadeInUp">
                                    <div className="orange-circle-img">
                                        <img src={require("../Components/images/project-1.png")} alt="" />
                                        <div className="orange-circle d-flex align-items-center ">
                                            <p className="text-white text-start">Open Plots </p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="project-images text-center wow animate__fadeInUp" data-wow-delay="0.5s">
                                    <div className="orange-circle-img">
                                        <img src={require("../Components/images/project-2.png")} alt="" />
                                        <div className="orange-circle d-flex align-items-center ">
                                            <p className="text-white text-start">Villas </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                                <div className="project-images text-center wow animate__fadeInUp" data-wow-delay="0.75s">
                                    <div className="orange-circle-img">
                                        <img src={require("../Components/images/project-3.png")} alt="" />
                                        <div className="orange-circle">
                                            <p className="text-white text-start">Budget Friendly Flats </p>
                                        </div>
                                    </div>                    
                                    <button className="text-center text-white">Know More</button>
                                </div>
                            </div> */}
                            <div className="col-md-3">
                                <div className="project-images text-center wow animate__fadeInUp" data-wow-delay="1s">
                                    <div className="orange-circle-img">
                                        <img src={require("../Components/images/project-4.png")} alt="" />
                                        <div className="orange-circle d-flex align-items-center">
                                            <p className="text-white text-start">Apartments</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bodha-review-sec">
                <div className="bodha-review-sec-inner">
                    <div className="container">
                        <div className="testimonial-blk">
                            <div className="testimonial-inner">
                            <Slider {...settings2}>
                                <div className="testimonial-1">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/testimonial-1.png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Saurabh Chariahh</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"I have moved 6 times in the last 25 years. Obviously, 
                                                we've dealt with many realtors both on the buying and selling side.
                                                 I have to say that <span> Bodha Homes Infra </span> is by far the BEST realtor we've ever worked with"
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-2">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewnew (3).png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Guna Sri</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"<span> Bodha Homes Infra </span> gave us many helpful suggestions on how to update our home.
                                            They are very professional and did a very in depth market analysis to help us determine in buying a new Home
                                            and This team is very responsive to any questions or concerns that we had and were always wonderful to deal with"
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-3">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewnew (2).png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Radha Krishna</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"When you're making a big financial, 
                                            personal and sometimes emotional decision, you want someone who will be a good listener, 
                                            a strong negotiator and who is respected his/her peers. <span> Bodha Homes Infra </span> meets all expectations"
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-4">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewnew (1).png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Ganesh</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"I was impressed with their level of knowledge and the 
                                            amount of data that they had about the market. It was a no-brainer for me to work with 
                                            them since I was set on buying in  <span> Bodha Homes Infra </span> and I’m so thankful I did"
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-5">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewed1.png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Arjun Rathod</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"<span> Bodha Homes Infra </span> gave us many helpful suggestions on how to update our home.
                                            They are very professional and did a very in depth market analysis to help us determine in buying a new Home
                                            and This team is very responsive to any questions or concerns that we had and were always wonderful to deal with"
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-6">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewed2.png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Seby Varghese</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"When you're making a big financial, 
                                            personal and sometimes emotional decision, you want someone who will be a good listener, 
                                            a strong negotiator and who is respected his/her peers. <span> Bodha Homes Infra </span> meets all expectations"
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-7">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewed3.png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Saket Agarwal</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"Their promptness, knowledge of the market, strategic mindset, kindness, 
                                            good energy made a potentially chaotic purchase process simple. I’ve already recommended him to 
                                            two of my good friends. When I am looking to buy again I will not hesitate to call  
                                            <span> Bodha Homes Infra .</span> "
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-8">
                                    <div className="testimonial-img text-center">
                                        <img src={require("../Components/images/reviewed4.png")} alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="reviewer">
                                            <h4 className="text-center">Viswanth</h4>
                                            <div className="star-icons d-flex justify-content-center">
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                                <div className="stars">
                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p className="text-center">"We had a very positive experience with <span> Bodha Homes Infra </span> 
                                            in search for & purchase of our new home. we stayed positive through the entire process.
                                            I would definitely recommend <span> Bodha Homes Infra </span>  to anyone looking to buy a home."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bodha-sec-4">
                <div className="bodha-sec-4-inner">
                    <div className="container">
                        <h2 className="text-center wow animate__zoomIn">Completed Projects</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="daimond-images-blk text-center wow animate__bounceInDown">
                                    <div className="diamond-img">
                                        <img src={require("../Components/images/completed-1.png")} alt="" />
                                    </div>
                                    <p><strong>Epuri Arcade</strong></p>
                                    <p>Atmakur, Kurnool</p>
                                    {/* <p className="min-height-para">Commercial Property at Atmakur, Kurnool</p>
                                    <p className="mt-2">This is completed Project</p> */}
                                    <Link to="/construction"><button className="text-white text-center">Know More</button></Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="daimond-images-blk text-center wow animate__bounceInUp">
                                    <div className="diamond-img">
                                        <img src={require("../Components/images/completed-2.png")} alt="" />
                                    </div>
                                    <p><strong>SKC Icon Square</strong></p>
                                    <p>Karutty, Angamaly. Cochin, Kerala</p>
                                    {/* <p className="min-height-para">SKC Vijaya Arcade , Angamaly, Cochin. Kerala</p>
                                    <p className="mt-2">This is completed Project</p> */}
                                    <Link to="/construction"><button className="text-white text-center">Know More</button></Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="daimond-images-blk text-center wow animate__bounceInDown">
                                    <div className="diamond-img">
                                        <img src={require("../Components/images/completed-3.png")} alt="" />
                                    </div>
                                    <p><strong>SKC Vijaya Arcade</strong></p>
                                    <p>Angamaly, Cochin. Kerala</p>
                                    {/* <p className="min-height-para">SKC Icon Square. Commercial Property Karutty, Angamaly. Cochin, Kerala</p>
                                    <p className="mt-2">This is completed Project</p> */}
                                    <Link to="/construction"><button className="text-white text-center">Know More</button></Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="daimond-images-blk text-center wow animate__bounceInUp">
                                    <div className="diamond-img">
                                        <img src={require("../Components/images/completed-4.png")} alt="" />
                                    </div>
                                    <p><strong>Sk Urban Homes</strong></p>
                                    <p>Atmakur, Kurnool</p>
                                    <Link to="/construction"><button className="text-white text-center">Know More</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bodha-sec-5">
                <div className="bodhaa-sec-5-inner">
                    <div className="container">
                        <h2 className="text-center">We  Believe</h2>
                        <div className="row justify-content-center align-items-center">
                            <div className="col">
                                <div className="icons-blk text-center wow animate__fadeInUp">
                                    <img src={require("../Components/images/icon-1.png")} alt="" />
                                    <p><strong>Trust</strong></p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="icons-blk text-center wow animate__fadeInUp" data-wow-delay="0.25s">
                                    <img src={require("../Components/images/icon-2.png")} alt="" />
                                    <p><strong>Quality</strong></p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="icons-blk text-center wow animate__fadeInUp" data-wow-delay="0.30s">
                                    <img src={require("../Components/images/icon-3.png")} alt="" />
                                    <p><strong>Benchmarks</strong></p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="icons-blk text-center wow animate__fadeInUp" data-wow-delay="0.40s">
                                    <img src={require("../Components/images/icon-4.png")} alt="" />
                                    <p><strong>Social <br/>Responsibility</strong></p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="icons-blk text-center wow animate__fadeInUp" data-wow-delay="0.5s">
                                    <img src={require("../Components/images/icon-5.png")} alt="" />
                                    <p><strong>Innovation</strong> </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="icons-blk text-center wow animate__fadeInUp" data-wow-delay="0.60s">
                                    <img src={require("../Components/images/icon-6.png")} alt="" />
                                    <p><strong>Customer <br/>Satisfaction</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bodha-sec-6">
                <div className="bodha-sec-6-inner">
                    <div className="container">
                        <h2 className="text-center">Videos</h2>
                        <div className="row">
                            <Slider {...settings}>
                                <div className="youtube-blk wow animate__fadeInLeft" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=aJMVeF-9DLA" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play1.jpg")} alt="" /></a>
                                    <p>Own a villa for just 71 lacs</p>
                                    {/* <div className="review-blk d-flex justify-content-between">
                                        <div className="views d-flex align-items-center">
                                            <i class="fa fa-eye" aria-hidden="true"></i><p>241,021</p>
                                        </div>
                                        <div className="likes d-flex align-items-center">
                                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i><p>2140</p>
                                            <i class="fa fa-thumbs-o-down" aria-hidden="true"></i><p>10</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="youtube-blk wow animate__fadeInDown" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=Mi3rmM-HPfQ" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play2.jpg")} alt="" /></a>
                                    <p>Quarter acre land for sale at Choutuppal</p>
                                </div>
                                <div className="youtube-blk wow animate__fadeInRight" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=DcqSJcx6bCo" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play3.jpg")} alt="" /></a>
                                     <p>Checklist for buying a Flat</p>
                                </div> 
                                <div className="youtube-blk wow animate__fadeInLeft" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=t5uOhDrL1xw" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play4.jpg")} alt="" /></a>
                                    <p>G+1 Villa for Sale at Ameenpur @ 1.45 Cr</p>
                                </div>
                                <div className="youtube-blk wow animate__fadeInDown" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=ytIXQoet1Go" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play5.jpg")} alt="" /></a>
                                    <p>4 Acres land for Sale @ D Nagaram. Choutuppal.</p>
                                </div>
                                <div className="youtube-blk wow animate__fadeInRight" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=dzDK32xZQvk" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play6.jpg")} alt="" /></a>
                                    <p>318 Sq yards West facing Plot for Sale at Mansanpally</p>
                                </div>
                                <div className="youtube-blk wow animate__fadeInDown" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=jqKyKPtVpXY" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play7.jpg")} alt="" /></a>
                                    <p>Right way to invest in Hyderabad</p>
                                </div>
                                <div className="youtube-blk wow animate__fadeInRight" data-wow-delay="0.25s">
                                    <a href="https://www.youtube.com/watch?v=0IdZTzlpKSM" target="_blank" rel="noopener noreferrer"><img src={require("../Components/images/play8.jpg")} alt="" /></a>
                                    <p>Business motivation | Josh Talks Telugu</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <Footer/>
  </div>;
};

export default Bodha;
