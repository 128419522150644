import React from "react";
import "./AboutUs.css";
import Modal from "react-modal";
import { useState } from "react";
import "../../Components/Modalstyles.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
// import VideoFile from "../../Components/images/Bodha-Video-3.mp4

const AboutUs = (props) => {
  // const [isOpen, setIsOpen] = useState(false);
  // function toggleModal() {
  //   setIsOpen(!isOpen);
  // }
  // const [isOpen2, setIsOpen2] = useState(false);
  // function toggleModal2() {
  //   setIsOpen2(!isOpen2);
  // }
  // const [isOpen3, setIsOpen3] = useState(false);
  // function toggleModal3() {
  //   setIsOpen3(!isOpen3);
  // }
  // const [isOpen4, setIsOpen4] = useState(false);
  // function toggleModal4() {
  //   setIsOpen4(!isOpen4);
  // }
  const [isOpen, setIsopen] = useState({
    video1: false,
    video2: false,
    video3: false,
    video4: false,
  });
  return (
    <div>
      <Header />
      <div className="about-us-sec">
        <div>
          <div className="inner-section single-banner">
            <div className="container">
              <h2>About Us</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="about-us-sec-inner">
          {/* <div className="about-sec-1">
              <div className="about-sec-1-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                        <div className="about-left-imgblk wow animate__fadeInLeft">
                          <img src={require("../../Components/images/about-us-1-left.png")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-right-contentblk wow animate__fadeInRight">
                            <div className="about-righ-inner">
                              <h1>All your Real Estate Investment Needs</h1>
                              <h2>@ One Place</h2>
                              <p className='text-dark'>Bodha Homes Infra has Firmly Established its Roots in Real Estate 
                                Industry from 2011. Started as a construction Firm, Now established 
                                as a Private Limited Company and has marked an incredible success in
                                 Hyderabad Real Estate Retail Industry.
                              </p>
                              <p className='text-dark'>Now with More than 1000 Clients, we are serving the Industry 
                                in various Domain marking ourselves as Marketing, Consulting & 
                                Construction Company serving people in Buying & Selling Properties 
                                and managing all their Investment Portfolios’.
                              </p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="about-sec-3">
            <div className="about-sec-3-inner">
              <div className="container">
                <div className="director-heading">
                  <h2 className="text-center wow animate__fadeInDown">
                    Our Directors
                  </h2>
                </div>
                <div className="directors-blk">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="director-text wow animate__fadeInRight">
                        <p class="">
                          Smitha Krishnamurthy Is a Visionary Highly Talented
                          and Sustainable Tough Leader in Real Estate Industry.
                          Smitha Krishnamurthy Worked with Multiple MNC
                          Companies in Key Roles, has gained overall Global
                          Exposure in Marketing & Sales.
                        </p>
                        <div className="person-info">
                          <h5 class="card-title">Smitha Krishnamurthy</h5>
                          <p class="card-title">Chairman & Founder</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="director-image wow animate__fadeInLeft">
                        {/* <img src={require("../../Components/images/smith-krishna-fullsize.png")} alt="" /> */}
                        <img
                          src={require("../../Components/images/smith-krishna-fullsize-1.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-sec-3-director-2">
            <div className="about-sec-3-director-2-inner">
              <div className="container">
                <div className="directors-blk-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="director-image wow animate__fadeInLeft">
                        <img
                          src={require("../../Components/images/epurikrishna-fullsize.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="director-text wow animate__fadeInRight">
                        <p class="mb-2">
                          Epuri Krishnamurthy Is a builder and Entrepreneur by
                          Profession. He’s a master in Financial Industry with a
                          Track Record of 11 years Experience in Banking Sector
                          & 10 years in Construction & Sales.
                        </p>
                        <p class="mb-2">
                          Epuri Krishnamurthy is well known for his
                          Revolutionary Decisions that has brought Adverse
                          Changes in Rural Real Estate Market.
                        </p>
                        <div className="person-info2">
                          <h5 class="card-title">Epuri Krishnamurthy</h5>
                          <p class="card-title">Managing Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-sec-3 cloned-section">
            <div className="about-sec-3-inner">
              <div className="container">
                <div className="director-heading">
                  <h2 className="text-center wow animate__fadeInDown">
                    Team Bodha
                  </h2>
                </div>
                {/* <div className="directors-blk">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="director-text wow animate__fadeInRight">
                        <p class="">
                          Mr.Vijith Jagadeesh is a Senior Architect and the
                          founder of M/s Vijith Jagadeesh Architects, Cochin,
                          Kerala. With over 25 years+ experience in
                          architecture, construction engineering and Contract
                          Advisory. He has designed projects that range from
                          city infrastructure projects to the design of
                          apartments, Hospitality, IT spaces, port
                          infrastructures, civil engineering projects, offices
                          and commercial projects.
                        </p>
                        <div className="person-info mb-3">
                          <h5 class="card-title">
                            Ar. Vijith Jagadeesh, B.Arch, IGBC-AP, F.I.V,
                            F.I.I.A
                          </h5>
                          <p class="card-title">Inhouse Architect</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="director-image wow animate__fadeInLeft">
                        <img
                          src={require("../../Components/images/Untitled-989898.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="about-sec-3-director-2 dirctror2-clonned">
                  <div className="about-sec-3-director-2-inner">
                    <div className="container"> */}
                      <div className="directors-blk">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="director-image wow animate__fadeInLeft">
                              <img
                                src={require("../../Components/images/Untitled-9999.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="director-text wow animate__fadeInRight">
                              <p class="mb-2">
                                Venkat Prakash Pudipeddi is a result driven
                                working person with 14 years proven experience
                                in Construction, Facilities Management,
                                Maintenance (MEP) and Project Management in
                                Government & Private Sector.
                              </p>
                              <p class="mb-2">
                                A Continous Learner with Vision and Mission,
                                working towards Development of Trust Business
                                and Personal Growth.
                              </p>
                              {/* <div className="affiliation-credits">
                          <div className="credits-title my-3">
                            <h5>Affiliations /Credits:</h5>
                          </div>
                          <ul class="affiliation-points">
                            <li>
                              Fellow Of Indian Institute of Architects.&
                              Chairman IIA Cochin Centre 20-22
                            </li>
                            <li>Fellow of Institution of Valuers.</li>
                            <li>
                              Indian Green Building Council -Accredited
                              Professional | IGBC.
                            </li>
                            <li>Member of Kerala Management Association.</li>
                            <li>
                              Chairman of the Infrastructure and Tender
                              committee .Regional Sports Centre.
                            </li>
                            <li>
                              Governing council and Life Member of Cancure.
                            </li>
                            <li>
                              General Council Member -CHed" Corporation of
                              Cochin.
                            </li>
                          </ul>
                        </div> */}
                              <div className="person-info2 mb-3">
                                <h5 class="card-title">
                                  {" "}
                                  Venkat Prakash Pudipeddi
                                </h5>
                                <p class="card-title">Executive Director</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    {/* </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="about-sec-3-director-2 dirctror2-clonned">
            <div className="about-sec-3-director-2-inner">
              <div className="container">
                <div className="directors-blk-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="director-image wow animate__fadeInLeft">
                        <img
                          src={require("../../Components/images/Untitled-9999.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="director-text wow animate__fadeInRight">
                        <p class="mb-2">
                          Venkat Prakash Pudipeddi is a result driven working
                          person with 14 years proven experience in
                          Construction, Facilities Management, Maintenance (MEP)
                          and Project Management in Government & Private Sector.
                        </p>
                        <p class="mb-2">
                          A Continous Learner with Vision and Mission, working
                          towards Development of Trust Business and Personal
                          Growth.
                        </p>
                      
                        <div className="person-info2 mb-3">
                          <h5 class="card-title"> Venkat Prakash Pudipeddi</h5>
                          <p class="card-title">Executive Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="about-video-sec">
              <div className="about-video-sec-inner">
              <video id="myVideo" loop="loop" autoPlay="autoplay" width="100%" height="70%">
                  <source src={VideoFile}  type="video/mp4" />
              </video>
              </div>
            </div> */}

          <div className="about-video-sec-2">
            <div className="video-sec-inner">
              <div className="container">
                <div className="director-heading">
                  <h2 className="text-center wow animate__fadeInDown">
                    Our Videos
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="youtube-about-blk wow animate__fadeInLeft">
                      <Modal
                        isOpen={isOpen.video1}
                        onRequestClose={() => {
                          setIsopen({ ...isOpen, video1: false });
                        }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww"
                      >
                        <div className="iframe-outer-sec">
                          <iframe
                            className="iframer-video"
                            src="https://www.youtube.com/embed/MDZ5kbi1Jes"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => {
                              setIsopen({ ...isOpen, video1: false });
                            }}
                          >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img
                        className="curse-p"
                        onClick={() => {
                          setIsopen({ ...isOpen, video1: true });
                        }}
                        src={require("../../Components/images/intro-video.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="youtube-about-blk wow animate__fadeInRight">
                      <Modal
                        isOpen={isOpen.video2}
                        onRequestClose={() => {
                          setIsopen({ ...isOpen, video2: false });
                        }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww"
                      >
                        <div className="iframe-outer-sec">
                          <iframe
                            className="iframer-video"
                            src="https://www.youtube.com/embed/RsjIeGylYYI"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => {
                              setIsopen({ ...isOpen, video2: false });
                            }}
                          >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img
                        className="curse-p"
                        onClick={() => {
                          setIsopen({ ...isOpen, video2: true });
                        }}
                        src={require("../../Components/images/qube-tv.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="youtube-about-blk wow animate__fadeInLeft"
                      data-wow-delay="0.25s"
                    >
                      <Modal
                        isOpen={isOpen.video3}
                        onRequestClose={() => {
                          setIsopen({ ...isOpen, video3: false });
                        }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww"
                      >
                        <div className="iframe-outer-sec">
                          <iframe
                            className="iframer-video"
                            src="https://www.youtube.com/embed/0IdZTzlpKSM"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => {
                              setIsopen({ ...isOpen, video3: false });
                            }}
                          >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img
                        className="curse-p"
                        onClick={() => {
                          setIsopen({ ...isOpen, video3: true });
                        }}
                        src={require("../../Components/images/josh-talks.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="youtube-about-blk wow animate__fadeInRight"
                      data-wow-delay="0.25s"
                    >
                      <Modal
                        isOpen={isOpen.video4}
                        onRequestClose={() => {
                          setIsopen({ ...isOpen, video4: false });
                        }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww"
                      >
                        <div className="iframe-outer-sec">
                          <iframe
                            className="iframer-video"
                            src="https://www.youtube.com/embed/jqKyKPtVpXY"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => {
                              setIsopen({ ...isOpen, video4: false });
                            }}
                          >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img
                        className="curse-p"
                        onClick={() => {
                          setIsopen({ ...isOpen, video4: true });
                        }}
                        src={require("../../Components/images/saurabh-interview.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-sec-2">
            <div className="about-sec-2-inner">
              <div className="container">
                <div className="our-services-blk">
                  <h5 className="text-center wow animate__fadeInDown">
                    Our Services
                  </h5>
                  <div className="our-services-para">
                    <p
                      className="text-center wow animate__fadeInDown"
                      data-wow-delay="0.20s"
                    >
                      The Company has diversified over Period of time into a
                      number of Real Estate Related Services each of them
                      handled & Executed with adroit Capacity.
                    </p>
                  </div>
                </div>
                <div className="row about-icons-blk">
                  <div className="col-md-3">
                    <div className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp">
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/buying-prop.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Buying & Selling Properties</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.20s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/real-investment.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Bulk Real Estate Investments</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.30s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/roi-based.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>ROI Based Investments</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.40s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/villa.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Villas</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.50s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/openplots.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Open Plots</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.60s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/agriculture.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Agricultural Lands</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.70s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/budgethomes.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Budget Homes</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.80s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/premiumprop.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Premium Properties</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="0.90s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/leagladv.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Legal Advice</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="1s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/investadv.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Real Estate Investment Advices</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="about-icons-inner-blk shadow d-flex align-items-center wow animate__fadeInUp"
                      data-wow-delay="1.20s"
                    >
                      <div className="services-blk-img">
                        <img
                          src={require("../../Components/images/interior.png")}
                          alt=""
                        />
                      </div>
                      <div className="about-services-content">
                        <p>Interior Designing</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-services-para2">
                  <p className="wow animate__fadeInUp" data-wow-delay="1.30s">
                    We are widely spread across South India with Completed and
                    On Going Projects at Kerala, Ooty, Kurnool & Hyderabad
                    Covering the Commercial, Retail, Residential and Hospitality
                    Segments.
                  </p>
                  <p className="wow animate__fadeInUp" data-wow-delay="1.40s">
                    We take pleasure in being a One Stop Point for every
                    Investor/Buyer looking out for secure Investment for the
                    Future at a well Developed Locality with Proper
                    Connectivity.Its our Determination & Commitment with
                    Excellence to Enable our Services – One to One.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
