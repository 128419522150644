import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AgricultureLands.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
// import VideoFile2 from "../../Components/images/bodha-video-4.mp4"
import HeaderForm from '../../Components/HeaderForm';
import Modal from 'react-modal/lib/components/Modal';

const AgricultureLands = () => {

  const [isOpenered, setIsOpenered] = useState({
    videod1: false,
    videod2: false,
    videod3: false
  });

  return (

    <div className="AgricultureLands">
      <Header />
      <div className="agriculture-sec-1">
        <div className="agriculture-sec-1-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="agri-right-blk">
                  <h2 className='text-white wow animate__fadeInLeft'>Agricultural Lands</h2>
                  <p className='text-white wow animate__fadeInLeft' data-wow-delay="0.30s"> Too much plotting and Construction is destroying the Earth.</p>
                  <p className='text-white wow animate__fadeInLeft' data-wow-delay="0.60s">Come let's preserve our Earth and make our Existance Sustainable.</p>
                </div>
              </div>
              <div className="col-md-0"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="agriculture-sec-2">
        <div className="agriculutre-sec-2-inner">
          <div className="container">
            <div className="row agriculture-page-row-divider">
              <div className="col-md-6">
                <div className="agri-sec-2-right wow animate__fadeInLeft">
                  <div className="agri-video-blk">
                    {/* <video id="myVideo2" loop="loop" width="100%" height="100%" controls>
                        <source src={VideoFile2} type="video/mp4" />
                      </video> */}
                    <Modal
                      isOpen={isOpenered.videod1}
                      onRequestClose={() => { setIsOpenered({ ...isOpenered, videod1: false }); }}
                      contentLabel="My dialog"
                      overlayClassName="myoverlay"
                      className="mymodal ww">
                      <div className="iframe-outer-sec">                        
                        {/* <iframe className="iframer-video" src="https://www.youtube.com/embed/1m4BUKcw0Xk?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                        {/* <iframe className="iframer-video" src="https://www.youtube.com/embed/eJEef0yMHTY?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                        <iframe className="iframer-video" src="https://www.youtube.com/embed/1m4BUKcw0Xk?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                      <div className="close-button">
                        <button
                          type="close"
                          className="text-danger"
                          onClick={() => { setIsOpenered({ ...isOpenered, videod1: false }); }}                                >
                          <i class="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </Modal>                    
                    <img src={require("../../Components/images/agri-video.png")} onClick={() => { setIsOpenered({ ...isOpenered, videod1: true }); }} className="video-poster" id="myVideo2" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="agri-sec-2-left wow animate__fadeInRight">
                  <h2>We at Bodha Homes Infra </h2>
                  <h3>Is making an effort to bring back a certain portion of life on Earth.</h3>
                  <p>We are putting our efforts to not to convert farming lands to plotting.
                    Rather we are selling agricultural lands and putting our efforts to
                    do farming in that land which can generate revenue in long run.
                  </p>
                  <p>We are not just saving the Earth but saving
                    the mankind by providing their daily needs.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="agri-sec-2-bottom-se">
                  <div className="bottom-agri-2-vid-sec">
                    <div className="agri-sec-2-right wow animate__fadeInLeft">
                      <div className="agri-video-blk">
                        {/* <video id="myVideo2" loop="loop" width="100%" height="100%" controls>
                            <source src={VideoFile2} type="video/mp4" />
                          </video> */}
                          <Modal
                            isOpen={isOpenered.videod2}
                            onRequestClose={() => { setIsOpenered({ ...isOpenered, videod2: false }); }}
                            contentLabel="My dialog"
                            overlayClassName="myoverlay"
                            className="mymodal ww">
                            <div className="iframe-outer-sec">                       
                              <iframe className="iframer-video" src="https://www.youtube.com/embed/J7OsiXZpAXI?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="close-button">
                              <button
                                type="close"
                                className="text-danger"
                                onClick={() => { setIsOpenered({ ...isOpenered, videod2: false }); }}>
                                <i class="fa-solid fa-xmark"></i>
                              </button>
                            </div>
                          </Modal>                    
                        <img src={require("../../Components/images/agri-video-3.png")} onClick={() => { setIsOpenered({ ...isOpenered, videod2: true }); }} className="video-poster" id="myVideo2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="agri-sec-2-bottom-se">
                  <div className="bottom-agri-2-vid-sec">
                    <div className="agri-sec-2-right wow animate__fadeInLeft">
                      <div className="agri-video-blk">
                          {/* <video id="myVideo2" loop="loop" width="100%" height="100%" controls>
                            <source src={VideoFile2} type="video/mp4" />
                            </video> */}
                          <Modal
                            isOpen={isOpenered.videod3}
                            onRequestClose={() => { setIsOpenered({ ...isOpenered, videod3: false }); }}
                            contentLabel="My dialog"
                            overlayClassName="myoverlay"
                            className="mymodal ww">
                            <div className="iframe-outer-sec">             
                              <iframe className="iframer-video" src="https://www.youtube.com/embed/8vwIPO0D1qQ?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="close-button">
                              <button
                                type="close"
                                className="text-danger"
                                onClick={() => { setIsOpenered({ ...isOpenered, videod3: false }); }}                                >
                                <i class="fa-solid fa-xmark"></i>
                              </button>
                            </div>
                          </Modal>                    
                        <img src={require("../../Components/images/agri-video-4.png")} onClick={() => { setIsOpenered({ ...isOpenered, videod3: true }); }} className="video-poster" id="myVideo2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agriculture-sec-3">
        <div className="agriculture-sec-3-inner">
          <div className="container">
            <div className="agri-sec-3-head">
              <h2 className='text-center'>Farming Can Be</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="products-blk wow animate__fadeInUp">
                  {/* <img src={require("../../Components/images/foodproducts.png")} alt="" /> */}
                  <img src={require("../../Components/images/foodproducts-1.png")} alt="" />
                  <div className="iconer">
                    <i class="fa-solid fa-wheat-awn"></i>
                  </div>
                  <h4>Food Products</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="products-blk wow animate__fadeInUp" data-wow-delay="0.30s">
                  <img src={require("../../Components/images/dairyproducts.png")} alt="" />
                  <div className="iconer">
                    <i class="fa-solid fa-cheese"></i>
                  </div>
                  <h4>Dairy Products</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="products-blk products-2-blk wow animate__fadeInUp" data-wow-delay="0.60s">
                  <img src={require("../../Components/images/animalproducts.png")} alt="" />
                  <div className="iconer">
                    <i class="fa-solid fa-bowl-food"></i>
                  </div>
                  <h4>Animal Products</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agriculture-sec-4">
        <div className="agriculture-sec-4-inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="agri-sec-4-head">
                  <h2 className='text-white text-center'>
                    We are on a fire to reduce the cost of food in present and future for human consumption.
                    We can save our ecosystem by developing Agriculture.
                  </h2>
                  <h3 className='text-white text-center'>Rent or income from agricultural land - <span>If So - Argued!!</span></h3>
                  <h3 className='text-white text-center'> <span>It Would be yes</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agriculture-sec-5">
        <div className="agriculture-sec-5-inner">
          <div className="container">
            <div className="agri-sec-5-head">
              <h2 className='text-center'>From ancient times India is a farming Country. So we would  <br /> never fail in generating Income from our farming System.</h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="agri-sec-5-left">
                  <ul>
                    <li className='wow animate__fadeInLeft'><i class="fa-solid fa-caret-right"></i><p>Individually we cannot do anything. <strong>Together we Can.</strong></p></li>
                    <li className='wow animate__fadeInLeft' data-wow-delay="0.30s"><i class="fa-solid fa-caret-right"></i><p>So Individually when you cannot acquire a bigger chunk of land, come and
                      <strong> collaborate with us.</strong></p>
                    </li>
                    <li className='wow animate__fadeInLeft' data-wow-delay="0.60s"><i class="fa-solid fa-caret-right"></i><p>You are not a buyer or Customer. <strong>You are our partner.</strong></p></li>
                  </ul>
                  <p className='below-texter wow animate__fadeInUp' data-wow-delay="0.90s">We are going to group people where you can purchase our acquired small chunks of land in portion of half or one acre portions catered at a very nominal prize.
                    You can buy them in groups or we can help you buy them in groups. </p>
                  <p className='below-texter wow animate__fadeInUp' data-wow-delay="0.90s">We will be your facilitator for farming and generating yearly income along with weekend stays.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="agri-sec-5-right">
                  <img className='old-shaker wow animate__fadeInRight' src={require("../../Components/images/shakes-hand.jpg")} alt="" />
                  <img className='new-old-shaker d-none wow animate__fadeInRight' src={require("../../Components/images/shakes-hand-767.png")} alt="" />
                </div>
              </div>
              <div className="new-texter">
                <p className='new-below-texter d-none text-center wow animate__fadeInUp' data-wow-delay="0.90s">We are going to group people where you can purchase our acquired small chunks of land in portion of half or one acre portions catered at a very nominal prize.
                  You can buy them in groups or we can help you buy them in groups.
                  We will be your facilitator for farming and generating yearly income along with weekend stays.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agriculture-sec-7">
        <div className="agriculture sec-7-inner">
          <div className="container">
            <div className="agriculture-page-form">
              {/* <div className="col-md-6">
              <div className="form-sign">
                <img src={require("../../Components/images/formfilling.png")} alt="" />
              </div>
            </div> */}
              <div className="agri-sec-7-head">
                <h2 className='text-center'>Come join our mission in Saving Earth</h2>
              </div>
              <div className="agri-form">
                <HeaderForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agriculture-sec-6">
        <div className="agriculture-sec-6-inner">
          <div className="container">
            <div className="agri-sec-6-head">
              <h2 className='text-center'>
                We at Bodha Homes facilitate properties, protecting your properties,<br />
                Revenue generation & Property Management Services.
              </h2>
            </div>
            <div className="agri-sec-6-second-head">
              <h2 className='text-center'>How can you Associate with us.</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="agri-partner-blk wow animate__fadeInDown">
                  <img src={require("../../Components/images/agreement.png")} alt="" />
                  <h4 className='text-center'> Be a partner in buying</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="agri-partner-blk wow animate__fadeInUp">
                  <img src={require("../../Components/images/lands.png")} alt="" />
                  <h4 className='text-center'> Be a partner in lending lands.</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="agri-partner-blk agri-3rd-partner wow animate__fadeInDown">
                  <img src={require("../../Components/images/money.png")} alt="" />
                  <h4 className='text-center'> Be a Prospective Investor.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )

};


export default AgricultureLands;
