import React from "react";
import './InteriorDesign.css';
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";


const InteriorDesign = () => {
  return <div>
    <Header/>
        <div className="interior-sec">
          <div className="interior-sec-inner">
            <div>
              <div className="inner-section single-banner">
                  <div className="container">
                    <h2>Interior Design</h2>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Interior Design
                      </li>
                    </ol>
                  </div>
                </div>
            </div>
            <div className="interior-design-sec-1 py-5">
              <div className="inerior-sec-1-inner">
                <div className="content-head-interior">
                  <h2 className="text-center">Coming Soon ..!</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
  </div>;
};

export default InteriorDesign;
