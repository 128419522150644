import React from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { SignupSchema, Validation } from "./Validation";
import "./HeaderForm.css";
import { toast } from "react-hot-toast";
import { Redirect, useHistory } from "react-router-dom";

const HeaderForm = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [datastore, setDatastore] = useState("");
  const onSubmi = async (Validation) => {

    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Validation)

    };

    const response = await fetch('https://backend.bodhahomesinfra.com/api/booking', requestOptions)
      .then((response) => {
        response.json();
        setDatastore(response)
        console.log("data", datastore)
        console.log("response", response)
        history.push("/thankyou")      
      });

      toast.success("Submitted Succesfuly")

  };
  function numbersOnly(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    else {
      console.log("You pressed a key: " + key);
    }
  }
  function textOnly(e) {
    var key = e.key;
    var regex = /^[a-zA-Z ]/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    else {
      console.log("You pressed a key: " + key);
    }
  }

  return <div>

    <Formik
      initialValues={Validation}
      validationSchema={SignupSchema}
      onSubmit={onSubmi}
      render={() => (
        <Form>
          <div className="register-form">
            <div className="register-form-inner">
              <div className="container">
                <div className="content-head">
                  <h2 className="text-start d-none second-content-head">Get In Touch</h2>
                  <h2 className="text-start d-none third-content-head">Free Webinars Every Monday / Friday</h2>
                  <h2 className="text-start text-white first-content-head">Talk to our experts</h2>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="name-field wow animate__fadeInUp" data-wow-delay="0.5s">
                      <Field onKeyPress={(e) => textOnly(e)} className="w-100" type="text" name="name" placeholder="Your Name" />
                      <p className="ms-3 mt-2"><ErrorMessage name="name" /></p>
                    </div>
                  </div>
                  <div className="col-md-3 wow animate__fadeInUp" data-wow-delay="0.75s">
                    <div className="email-field">
                      <Field className="w-100" type="email" name="email" placeholder="Email Address" />
                      <p className="ms-3 mt-2"><ErrorMessage name="email" /></p>
                    </div>
                  </div>
                  <div className="col-md-3 wow animate__fadeInUp" data-wow-delay="1s">
                    <div className="phone-field">
                      <Field onKeyPress={(e) => numbersOnly(e)} className="w-100" type="tel" maxLength={10} name="mobile" placeholder="Mobile Number" />
                      <p className="ms-3 mt-2"><ErrorMessage name="mobile" /></p>
                    </div>
                  </div>
                  <div className="col-md-3 old-column-agri wow animate__fadeInUp" data-wow-delay="1.25s">
                    <div className="register-form-submit text-left">
                      <button type="submit" className="old-buttoner">Book Now</button>
                      <button type="submit" className="new-buttoner d-none">Reach Us</button>
                    </div>
                  </div>
                  <div className="col-md-2 d-none new-column-agri wow animate__fadeInUp" data-wow-delay="1.25s">
                    <div className="register-form-submit text-left">
                      <button type="submit" className="old-buttoner">Book Now</button>
                      <button type="submit" className="new-buttoner d-none">Reach Us</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    />

  </div>;
};

export default HeaderForm;
