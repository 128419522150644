import React from "react";
import "./LandingStrip.css"

const LandingStrip = () => {
    return <div>
        <div className="landingstrip-sec">
            <div className="landingstripinner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="number-social-blk d-flex align-items-center justify-content-center">
                                <div className="number-landing text-white me-4 d-flex align-items-center">
                                    <i className="fa-solid fa-phone me-2"></i><a href="tel:7569922847">+91 7569922847</a>
                                    <p className="mx-4">|</p>
                                    <i className="fa-solid fa-at me-2"></i><a href="mailto:info@bodhahomesinfra.com">info@bodhahomesinfra.com</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-none socio-767-blk">
                                <div className="strip-icon">
                                    <a href="tel:7569922847"><div className="socio-inner phoner-ic"><i className="fa-solid fa-phone"></i></div></a>
                                </div>
                                <div className="strip-icon">
                                    <a href="mailto:info@bodhahomesinfra.com"><div className="socio-inner mx-2 mailer-ic"><i className="fa-solid fa-at"></i></div></a>
                                </div>
                            </div>
                            <div className="social-landing d-flex justify-content-end">
                                <a href="https://www.facebook.com/profile.php?id=100088730526085" target={"_blank"}><div className="social-circle face-book"><i className="fa fa-facebook text-white" aria-hidden="true"></i></div></a>
                                <a href="https://www.instagram.com/bodhahomesinfra/" target={"_blank"}><div className="social-circle mx-2 instagram"><i className="fa-brands fa-instagram text-white"></i></div></a>
                                <a href="https://www.youtube.com/@bodhahomesinfra5661" target={"_blank"}><div className="social-circle utube"><i className="fa-brands fa-youtube text-white"></i></div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default LandingStrip;
