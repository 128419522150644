import React from "react";
import './Construction.css';
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";


const Construction = () => {
  return <div>
    <Header/>
      <div className="construct-sec">
          <div>
            <div className="inner-section single-banner">
                <div className="container">
                  <h2>Construction</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Construction
                    </li>
                  </ol>
                </div>
              </div>
          </div>
        <div className="construct-sec-inner">
          <div className="construct-3" id="vijaya-sec">
              <div className="construct-3-inner">
                <div className="container">
                    <div className="vijaya-arcade">
                      <div className="row bigger-shadow">
                        <div className="col-md-6">
                            <div className="arcade-3-left wow animate__fadeInRight">
                              <img src={require("../../Components/images/arcade-img-3.png")} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="arcade-3-right wow animate__fadeInLeft">
                              <div className="arcade-heading">
                                <h2>SKC Vijaya Arcade</h2>                              
                              </div>
                              <div className="arcade-content">
                                  <ul>
                                    <li>Commercial Complex</li>
                                    <li>Built on Total: 17.5 Cents with a Total 13000 sft built up Area.</li>
                                    <li>Year of Completion: 2013</li>
                                    <li>Location : <span> Angamaly, NH 47. Facing National Highway</span></li>
                                  </ul>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          <div className="construct-2" id="skn-sec">
            <div className="construct-2-inner">
              <div className="container">
                  <div className="skn-iconsquare">
                    <div className="row bigger-shadow">
                      <div className="col-md-6">
                          <div className="arcade-2-left wow animate__fadeInLeft">
                            <div className="arcade-heading">
                              <h2>SKC Icon Square</h2>                              
                            </div>
                            <div className="arcade-content">
                                <ul>
                                  <li>Commercial Complex</li>
                                  <li>Built on Total 9.5 Cents with a built up area of 10000 Sft</li>
                                  <li>Year of Completion: 2018</li>
                                  <li>Location : <span> Karuty, Angamaly, Kerala</span></li>
                                </ul>
                            </div> 
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="arcade-2-right wow animate__fadeInRight">
                            <img src={require("../../Components/images/arcade-img-2.png")} alt="" />
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="construct-1" id="epuri-sec">
            <div className="construct-1-inner">
              <div className="container">
                  <div className="epuri-arcade">
                    <div className="row bigger-shadow">
                      <div className="col-md-6">
                        <div className="arcade-1-left wow animate__fadeInRight">
                          <img src={require("../../Components/images/arcade-img-1.png")} alt="" />
                        </div> 
                      </div>
                      <div className="col-md-6">
                        <div className="arcade-1-right wow animate__fadeInLeft">
                          <div className="arcade-heading">
                            <h2>Epuri Arcade</h2>                              
                          </div>
                          <div className="arcade-content">
                            <ul>
                              <li>Commercial Complex</li>
                              <li>Built on 5 Cents with a built up area of 6000 Sft.</li>
                              <li>Year of Completion: 2021</li>
                              <li>Commercial Highway Facing</li>
                              <li>Location: <span>Atmakur, Kurnool.</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="construct-4" id="urban-sec">
            <div className="construct-4-inner">
              <div className="container">
                  <div className="urban-homes">
                    <div className="row bigger-shadow">
                      <div className="col-md-6">
                          <div className="arcade-4-left wow animate__fadeInLeft">
                            <div className="arcade-heading">
                              <h2>Sk Urban Homes</h2>                              
                            </div>
                            <div className="arcade-content">
                                <ul>
                                  <li>Ongoing Villa Project at Atmakur, Kurnool.</li>
                                  <li>Total 3 Acres Property with Plotting & Villas</li>
                                  <li>Year of Completion: 2021</li>
                                  <li>Total No of Villas: 14</li>
                                </ul>
                            </div>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="arcade-4-right wow animate__fadeInRight">
                            <img src={require("../../Components/images/arcade-img-4.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
  </div>;
};

export default Construction;


