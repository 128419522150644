import React from "react";
import { Link } from "react-router-dom";
import "./ThankyouPage.css"

const ThankyouPage = () => {
  return <div>
        <div className="thankyou-page">
            <div className="thankyou-page-sec-1">
                <div className="thankyou-page-sec-inner">
                    <div className="conatiner">
                        <div className="check-img-sec text-center">
                            {/* <img src={require("../Components/images/thankyou-page-tik.png")} alt="" /> */}
                            <img src={require("../Components/images/thankyou-page-tik-2.png")} alt="" />
                            <div className="thankyou-desc mb-4">
                                <h2 className="text-center">Your Details Has Been <span>Successfully</span>  Submitted</h2>
                                <h3 className="text-center">We will get in touch with you shortly</h3>
                            </div>
                            <div className="proceed-to-page">
                                <Link to="/">Proceed to our page  <i class="fa-solid fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>;
};

export default ThankyouPage;
